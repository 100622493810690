<template>
  <div>
    <section class="text-center">
        <b-card-body>
          <h2 class="text-primary">
            Create your template
          </h2>
          <b-card-text class="mb-2">
            <span>Vivamus in suscipit nisl. Praesent rutrum vestibulum suscipit. Nullam at lacus id mauris fringilla imperdiet at in purus. Vivamus pellentesque ipsum in ultrices porttitor.</span>
          </b-card-text>
        </b-card-body>
    </section>

    <section>
      <!-- content -->
      <b-row class="d-flex justify-content-center">
        <b-col
          md="4"
          sm="6"
        >
          <b-card
            :img-src="require('@/assets/images/img/template.png')"
            class="text-center cursor-pointer"
          >
            
            <h4>Use our predefined Templates</h4>
            <b-card-text class="mt-1">
              There is perhaps no better demonstration of the folly of image of our tiny world.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          md="4"
          sm="6"
        >
          <b-card
            :img-src="require('@/assets/images/img/template-1.png')"
            class="text-center cursor-pointer"
            @click="$router.push({ name: 'software-step02'})"
          >
            <h4>Create Blank Template</h4>
            <b-card-text class="mt-1">
              There is perhaps no better demonstration of the folly of image of our tiny world.
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/software.scss';
</style>